import React from "react";
import NavbarMain from "./NavbarMain";
import { Footer } from "./Footer";
import "./homepagestyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import coin from "../Images/coin.png";
import women from "../Images/women.png";

const Contactus = () => {
  return (
    <div>
      <NavbarMain />
      <div className="about-banner container mx-auto py-5">
        <h6 style={{ textAlign: "start" }}>CONTACT US </h6>
        <h2>
          Get in Touch with PayBi <span>We’re Here to Help!</span>
        </h2>
        <p>
          Have a question or need assistance? Our team is ready to help you with
          anything you need. Whether you’re looking for support or want to know
          more about PayBi’s features, reach out to us anytime. We’re just a
          message away
        </p>
        <a href="https://app.paybi.in/" target="_blank" rel="noopener noreferrer">

        <button type="button">
          Get Started Now{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M6.5 18.4166L11.9167 13L6.5 7.58331"
              stroke="#191000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0833 18.4166L19.4999 13L14.0833 7.58331"
              stroke="#191000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        </a>
      </div>
      <div className="con-form container mx-auto py-5">
        <div className="form-img">
          <img src={women} className="img-fluid" alt="" />
        </div>
        <div className="form-des me-auto">
          <h2>Get Your Queries by Paybi</h2>
          <p>
            Drop us an email with your questions, suggestions, or even just to
            say hello. We'll get back to you at the earliest.
          </p>
          <form action="">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Your mail Address"
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Your Queries"
              required
            />
            <button type="submit">Submit Now</button>
          </form>
        </div>
      </div>
      <div className="con-all py-5">
      <div className="new-contact row container mx-auto">
  <div className="col-md-6">
    <div className="left-all">
      <img src={coin} className="img-fluid" alt="" />
      <h2>Visit Our Office</h2>
      <p>No. 709, 2nd Floor, Spencer Plaza, Anna Road, Chennai - 600002.</p>
      <a href="tel:+919345910837">+(91) 93459 10837</a> <br />
      <a href="https://www.PayBi.in" target="_blank" rel="noopener noreferrer">www.PayBi.in</a>
    </div>
  </div>

  <div className="col-md-6 d-block d-md- justify-content-center align-items-center pt-md-5 pt-1">
  <div className="map-container">

    {/* <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5953110604655!2d80.25872747454791!3d13.06141191290389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1729158682547!5m2!1sen!2sin"
      width="100%" 
      height="350" 
      style={{ border: 0 }}
      allowFullScreen="" 
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe> */}
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5953110604655!2d80.25872747454791!3d13.06141191290389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1729158682547!5m2!1sen!2sin"
  width="100%" 
  height="450" 
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
  title="Google Map of Spencer Plaza"
/>

  </div>
  </div>

</div>

      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
