
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Homepage from './Components/Homepage';
import Aboutus from './Components/Aboutus';
import Contactus from './Components/Contactus';



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/Contactus" element={<Contactus />} />


        </Routes>
      </Router>

    </>
  );
}

export default App;
