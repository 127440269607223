import React from "react";
import NavbarMain from "./NavbarMain";
import { Footer } from "./Footer";
import "./homepagestyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import group from "../Images/aboutgroup.png";
import mission from "../Images/mission.png";
import story from "../Images/story.png";

const Aboutus = () => {
  return (
    <div>
      <NavbarMain />
      <div className="about-banner container mx-auto py-5">
        <h6 style={{ textAlign: "start" }}>ABOUT US</h6>
        <h2>
          Simplifying Your Financial World with <span>Paybi</span>
        </h2>
        <p>
          we make managing money easy, safe, and hassle-free. Whether it's
          payments, tracking expenses, or budgeting, our platform gives you full
          control, anytime, anywhere. Join thousands of Indians already
          simplifying their finances with PayBi
        </p>
        <a href="https://app.paybi.in/" target="_blank" rel="noopener noreferrer">

        <button type="button">
          Get Started Now{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M6.5 18.4166L11.9167 13L6.5 7.58331"
              stroke="#191000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0833 18.4166L19.4999 13L14.0833 7.58331"
              stroke="#191000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        </a>
      </div>
      <div className="about-group pt-5 container mx-auto">
        <img src={group} className="img-fluid p-0 m-0" alt="" />
      </div>
      <div className="our-mission-all container mx-auto mb-5">
        <div className="our-mission container mx-auto pt-5">
          <div className="row container">
            <div className="col-md-4 col-12 d-flex justify-content-center align-items-center">
              <img src={mission} className="img-fluid" alt="" />
            </div>
            <div className="col-md-8 col-12">
              <div className="mission-des ">
                <h4>“Our Mission </h4>

                <p>
                  o empower individuals and businesses with simple, secure, and
                  accessible financial solutions. We aim to make money
                  management effortless, helping users take control of their
                  finances. With PayBi, everyone can make smarter financial
                  decisions. Our goal is to create a more financially inclusive
                  India. We're here to make managing money easier for everyone,
                  anytime, anywhere.
                </p>
                {/* <br /> */}
                <p>
                  o empower individuals and businesses with simple, secure, and
                  accessible financial solutions. We aim to make money
                  management effortless, helping users take control of their
                  finances. With PayBi, everyone can make smarter financial
                  decisions. Our goal is to create a more financially inclusive
                  India. We're here to make managing money easier for everyone,
                  anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="our-story py-5 my-5">
          <img src={story} className="img-fluid" alt="" />
          <div className="story-des">
            <h4>“Our Story</h4>
            <p>
              PayBi was born out of a vision to simplify financial management
              for every Indian. We recognized the challenges of keeping track of
              expenses, making secure payments, and budgeting effectively in
              today’s fast-paced world. Our goal is to provide an easy-to-use
              platform that empowers people to take control of their finances
              with confidence. From everyday transactions to long-term financial
              planning, PayBi is here to make managing money simpler and more
              accessible for everyone across India.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
