import React from 'react';
import Logo from "../Images/PaypiLogo.png"; // Ensure this path is correct

export const Footer = () => {
  return (
    <>
      <div className='container-fluid footer_container'>
        <div className="row px-3 mx-auto">
          
          {/* Column 1: Logo and address */}
          <div className="col-12 col-md-4">
            <div className="footer_sec1 ps-0  ps-md-5">
              <img src={Logo} className='footerLogo' alt="Paypi Logo" />
              <p>
                Address: No. 709, 2nd Floor, Spencer Plaza, <br /> Anna Road, Chennai - 600002.
              </p>
              <a href="tel:+919345910837" className="d-block">+(91) 93459 10837</a>
              <a href="https://www.PayBi.in" className="d-block" target="_blank" rel="noopener noreferrer">www.PayBi.in</a>
            </div>
          </div>
          
          {/* Column 2: Quick Links */}
          <div className="col-12 col-md-2">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/about">Features</a></li>
              <li><a href="/services">Pricing</a></li>
              <li><a href="/contact">Blog</a></li>
              <li><a href="/blog">Contact Us</a></li>
              <li><a href="/blog">FAQs</a></li>
            </ul>
          </div>

          {/* Column 3: Company Info */}
          <div className="col-12 col-md-2">
            <h5>Company Info</h5>
            <ul className="list-unstyled">
              <li><a href="/">About Us</a></li>
              <li><a href="/about">Careers</a></li>
              <li><a href="/services">Press</a></li>
              <li><a href="/contact">Partner with Us</a></li>
              <li><a href="/blog">Investor Relations</a></li>
            </ul>
          </div>

          {/* Column 4: Policies */}
          <div className="col-12 col-md-2">
            <h5>Policies</h5>
            <ul className="list-unstyled">
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/about">Terms & Conditions</a></li>
              <li><a href="/services">Security</a></li>
            </ul>
          </div>

          {/* Column 5: Payments */}
          <div className="col-12 col-md-2">
            <h5>Payments</h5>
            <ul className="list-unstyled">
              <li><a href="/">Visa</a></li>
              <li><a href="/about">Mastercard</a></li>
              <li><a href="/services">Rupay</a></li>
              <li><a href="/contact">UPI</a></li>
            </ul>
          </div>
        </div>
      </div>
      <p className='copyrights'>© 2024 PayBi. All rights reserved.</p>
    </>
  );
};
