import React, { useState } from "react";
import Logo from "../Images/PaypiLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const NavbarMain = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container-fluid navmain_container">
      <div className="nav_items">
        <div className="burger_icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} size="lg" color="white" />
        </div>
        <div className="logo_container">
          <a href="/">
            {" "}
            <img src={Logo} alt="" />
          </a>
        </div>
        <div className="nav_list_items">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">Features</a>
            </li>
            <li>
              <a href="/">Pricing</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/Contactus">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="Login_btn">
          <a
            href="https://app.paybi.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </a>
        </div>
      </div>

      {/* Mobile Side Menu */}
      <div className={`mobile_menu ${isMenuOpen ? "open" : ""}`}>
        <div className="close_btn" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">Features</a>
          </li>
          <li>
            <a href="/">Pricing</a>
          </li>
          <li>
            <a href="/aboutus">About Us</a>
          </li>
          <li>
            <a href="/Contactus">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarMain;
